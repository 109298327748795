import { apiClient, handleResponse } from './axios';

async function createWebinaire(body) {
  try {
    const response = await apiClient
      .post('/zoom/meeting', body);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getWebinaires(body) {
  try {
    const response = await apiClient
      .get('/webinar', body);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function addRegistrant(body) {
  try {
    const response = await apiClient
      .put('/webinar/registrant', body);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function deleteRegistrant(webinaireId, userId) {
  try {
    const response = await apiClient
      .put(`/webinar/registrant/delete/${webinaireId}/${userId}`);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getZoomLink(meetingId) {
  try {
    const response = await apiClient
      .get(`/zoom/meeting/single/${meetingId}`);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getRecording(fileName) {
  try {
    const response = await apiClient
      .get(`/googlestorage/download/webinar/${fileName}`);
    return response;
  } catch (error) {
    return error.response;
  }
}

const webinaireServices = {
  createWebinaire,
  getWebinaires,
  addRegistrant,
  getZoomLink,
  getRecording,
  deleteRegistrant,
};

export default webinaireServices;
