/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import moment from 'moment';
import webinaireImg from '../img/webinaire_1.jpg';
import webinaireServices from '../../redux/services/webinaireServices';

const UpcomingWebinaire = ({ userData, setSuccessSnackBar, setErrorSnackBar }) => {
  const [webinaires, setWebinaires] = useState([]);

  const getWebinairesData = async () => {
    const webinaireRes = await webinaireServices.getWebinaires();
    if (webinaireRes && webinaireRes.length > 0) {
      const newWebinaires = webinaireRes.filter((webinaire) => new Date(webinaire.start) >= new Date());
      setWebinaires(newWebinaires);
    } else {
      setErrorSnackBar({ code: 400, message: 'Failed to fetch webinaires', severity: 'error' });
    }
  };

  useEffect(() => {
    getWebinairesData();
  }, []);

  const handleReservation = async (data) => {
    const body = {
      meetingId: data.meetingId,
      webinaireId: data._id,
      firstname: userData.firstname,
      lastname: userData.name,
      email: userData.email,
      userId: userData._id,
    };

    const registrationRes = await webinaireServices.addRegistrant(body);
    if (registrationRes && registrationRes._id) {
      const cpyWebinaires = [...webinaires];
      cpyWebinaires.forEach((webinaire) => {
        if (webinaire._id === registrationRes._id) {
          webinaire.registered = registrationRes.registered;
        }
      });
      setWebinaires(cpyWebinaires);
      setSuccessSnackBar({ severity: 'success', message: 'Inscription effectuée' });
    } else {
      setErrorSnackBar({ code: 400, severity: 'error', message: 'Echec de l\'inscription' });
    }
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundSize: '700px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.50), rgba(255, 255, 255, 0.50)), url(${webinaireImg})`,
        backgroundPosition: 'center',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {webinaires.length > 0 && webinaires.map((webinaire) => (
        <Paper
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="body2">{moment(webinaire.start).format('DD/MM/YYYY - hh:mm')}</Typography>
            <Typography variant="body2">{`places restantes: ${webinaire.places - webinaire.registered.length}`}</Typography>
          </div>
          <Typography variant="body2">{`Durée: ${webinaire.duration / 60}h${webinaire.duration % 60 === 0 ? '' : webinaire.duration % 60}`}</Typography>
          <Typography variant="h6" style={{ marginBottom: '20px', marginTop: '20px' }}>
            {webinaire.topic}
          </Typography>
          <Typography variant="body2" align="justify">{parse(webinaire.blurb)}</Typography>
          <Button
            onClick={() => handleReservation(webinaire)}
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            style={{
              display: 'flex',
              alignSelf: 'end',
            }}
            disabled={webinaire.registered.includes(userData._id) || webinaire.registered.length === webinaire.places}
          >
            {webinaire.registered.includes(userData._id) ? 'Réservé' : 'Réserver'}
          </Button>
        </Paper>
      ))}
    </div>
  );
};

export default UpcomingWebinaire;
