/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import webinaireImg from '../img/webinaire_2.jpg';
import webinaireServices from '../../redux/services/webinaireServices';

const OldWebinaire = ({ setErrorSnackBar }) => {
  const [webinaires, setWebinaires] = useState([]);

  const getWebinairesData = async () => {
    const webinaireRes = await webinaireServices.getWebinaires();
    if (webinaireRes && webinaireRes.length > 0) {
      const newWebinaires = webinaireRes.filter((webinaire) => new Date(webinaire.start) < new Date());
      setWebinaires(newWebinaires);
    } else {
      // toastError('Failed to fetch webinaires');
      setErrorSnackBar({ code: 400, message: 'Failed to fetch webinaires', severity: 'error' });
    }
  };

  useEffect(() => {
    getWebinairesData();
  }, []);

  const handleDownload = async (filename) => {
    const downloadedFile = await webinaireServices.getRecording(filename);
    const buffer = Buffer.from(downloadedFile.data[0]);
    const blob = new Blob([buffer]);

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename.replace('resources/video/', '');
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundSize: '700px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.50), rgba(255, 255, 255, 0.50)), url(${webinaireImg})`,
        backgroundPosition: 'center',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {webinaires.length > 0 && webinaires.map((webinaire) => (
        <Paper
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h6">{webinaire.topic}</Typography>
          </div>
          <Typography variant="body2" align="justify">{parse(webinaire.blurb)}</Typography>
          <Button
            onClick={() => handleDownload(webinaire.recording)}
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            style={{
              display: 'flex',
              alignSelf: 'end',
            }}
            disabled={!webinaire.recording}
          >
            {webinaire.recording ? 'Télécharger' : 'En attente'}
          </Button>
        </Paper>
      ))}
    </div>
  );
};

export default OldWebinaire;
