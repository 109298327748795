/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import parse from 'html-react-parser';
import moment from 'moment';
import webinaireImg from '../img/webinaire_3.jpg';
import webinaireServices from '../../redux/services/webinaireServices';
import { Loading } from '../../helpers/utils';

const RegisteredWebinaire = ({ userData, setErrorSnackBar, setSuccessSnackBar }) => {
  const [webinaires, setWebinaires] = useState([]);
  const [loading, setLoading] = useState(true);

  const getWebinairesData = async () => {
    const webinaireRes = await webinaireServices.getWebinaires();
    if (webinaireRes && webinaireRes.length > 0) {
      const newWebinaires = webinaireRes.filter((webinaire) => webinaire.registered.includes(userData._id));
      await Promise.all(newWebinaires.map(async (webinaire) => {
        const meetingData = await webinaireServices.getZoomLink(webinaire.meetingId);
        if (meetingData && meetingData.join_url) {
          webinaire.zoomLink = meetingData.join_url;
        }
      }));
      setWebinaires(newWebinaires);
      setLoading(false);
    } else {
      setErrorSnackBar({ code: 400, message: 'Failed to fetch webinaires', severity: 'error' });
      setLoading(false);
    }
  };

  const handleAnnulation = async (webinaireId) => {
    const annulationRes = await webinaireServices.deleteRegistrant(webinaireId, userData._id);
    if (annulationRes && annulationRes._id) {
      const webinairesArr = [];
      webinaires.forEach((webinaire) => {
        if (webinaire._id !== webinaireId) {
          webinairesArr.push(webinaire);
        }
      });
      setWebinaires(webinairesArr);
      setSuccessSnackBar({ severity: 'success', message: 'Annulation réussie' });
    } else {
      setErrorSnackBar({ code: 400, message: 'Echec de l\'annulation', severity: 'error' });
    }
  };
  useEffect(() => {
    getWebinairesData();
  }, []);

  return (
    <div
      style={{
        width: '100%',
        backgroundSize: '700px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.50), rgba(255, 255, 255, 0.50)), url(${webinaireImg})`,
        backgroundPosition: 'center',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {!loading ? (
        <>
          {webinaires.length > 0 && webinaires.map((webinaire) => (
            <Paper
              style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="body2">{moment(webinaire.start).format('DD/MM/YYYY - hh:mm')}</Typography>
                <Typography variant="body2">{`places restantes: ${webinaire.places - webinaire.registered.length}`}</Typography>
              </div>
              <Typography variant="body2">{`Durée: ${webinaire.duration / 60}h${webinaire.duration % 60 === 0 ? '' : webinaire.duration % 60}`}</Typography>
              <Typography variant="h6" style={{ marginBottom: '20px', marginTop: '20px' }}>
                {webinaire.topic}
              </Typography>
              <Typography variant="body2" align="justify">{parse(webinaire.blurb)}</Typography>
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'end',
                  gap: '15px',
                }}
              >
                <Button
                  component={Link}
                  href={webinaire.zoomLink}
                  target="_blank"
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="small"
                  style={{
                    display: 'flex',
                    textDecoration: 'none',
                  }}
                >
                  Rejoindre
                </Button>
                <Button
                  component={Link}
                  onClick={() => handleAnnulation(webinaire._id)}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="small"
                  style={{
                    display: 'flex',
                    textDecoration: 'none',
                  }}
                >
                  Annuler
                </Button>
              </div>
            </Paper>
          ))}
        </>
      )
        : <Loading />}
    </div>
  );
};

export default RegisteredWebinaire;
